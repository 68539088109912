import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const SEVERITY = {
  INF: 'info',
  WRN: 'warning',
};

const useStyles = makeStyles(() => ({
  alert: {
    background: 'rgba(255, 255, 255, 0.5)',
  },
}));

const Notices = ({ notices }) => {
  const classes = useStyles();
  const [now, setNow] = useState(moment());

  useEffect(() => {
    const clock = setInterval(() => setNow(moment()), 1000);
    return () => clearInterval(clock);
  }, []);

  const inScheduledTime = ({
    scheduleDays,
    scheduleStartTime,
    scheduleEndTime,
  }) => {
    const scheduleTimeRegExp = /^A_(?<hh>\d{2})(?<mm>\d{2})$/;

    const { hh: startHH, mm: startMM } = scheduleStartTime.match(
      scheduleTimeRegExp
    ).groups;

    const { hh: endHH, mm: endMM } = scheduleEndTime.match(
      scheduleTimeRegExp
    ).groups;

    if (scheduleDays === 'SUN' && moment().isoWeekday() !== 7) {
      return false;
    }

    const today = now.clone();
    const tomorrow = now.clone().add(1, 'days');

    const start = moment.tz(
      `${today.format('YYYY-MM-DD')}T${startHH}:${startMM}:00`,
      'US/Mountain'
    );

    let end;
    if (endHH === '24') {
      end = moment.tz(
        `${tomorrow.format('YYYY-MM-DD')}T00:00:00`,
        'US/Mountain'
      );
    } else {
      end = moment.tz(
        `${today.format('YYYY-MM-DD')}T${endHH}:${endMM}:00`,
        'US/Mountain'
      );
    }

    return now.isAfter(start) && now.isBefore(end);
  };

  const currentNotices = notices.filter(inScheduledTime);

  return (
    <Box mt={currentNotices.length > 0 ? 2 : 0}>
      {notices.map((notice) => (
        <Box
          key={notice.id}
          display={inScheduledTime(notice) ? 'block' : 'none'}
          mt={2}
        >
          <Alert
            severity={SEVERITY[notice.severity]}
            variant="outlined"
            className={classes.alert}
          >
            <AlertTitle>{notice.title}</AlertTitle>
            <div>{notice.message}</div>
            {notice.buttonUrl && (
              <Box mt={1}>
                <Button
                  color="inherit"
                  size="small"
                  {...(notice.buttonUrl.match(/^https?:/)
                    ? {
                        href: notice.buttonUrl,
                      }
                    : {
                        component: Link,
                        to: notice.buttonUrl,
                      })}
                >
                  {notice.buttonText || 'See More'}
                </Button>
              </Box>
            )}
          </Alert>
        </Box>
      ))}
    </Box>
  );
};

Notices.propTypes = {
  notices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Notices;
