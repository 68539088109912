import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import RelativeMoment from './RelativeMoment';

const useStyles = makeStyles((theme) => ({
  progressText: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  spinner: {
    marginRight: theme.spacing(1),
  },
  timestamp: {
    color: theme.palette.grey[300],
    cursor: 'default',
  },
  snackbar: {
    '& .MuiSnackbarContent-root': {
      flexDirection: 'column',
      justifyContnet: 'center',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    '& .MuiSnackbarContent-action': {
      margin: 0,
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
      },
    },
  },
}));

const Deploy = () => {
  const [run, setRun] = useState(null);
  const [startDeploy, setStartDeploy] = useState(false);
  const [cancelDeploy, setCancelDeploy] = useState(false);

  const classes = useStyles();

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          adminBaseUrl
        }
      }
    }
  `);

  const { adminBaseUrl } = site.siteMetadata;

  useEffect(() => {
    const timeouts = [];

    const check = async () => {
      try {
        const response = await axios.get(`${adminBaseUrl}/deploy/status`, {
          crossDomain: true,
          withCredentials: true,
        });

        setRun({
          status: response.data.status,
          lastRun: response.data.last_run,
          conclusion: response.data.conclusion,
        });

        if (response.data.status === 'completed') {
          setCancelDeploy(false);
        } else {
          setStartDeploy(false);
        }

        const timeout = window.setTimeout(check, 5000);
        timeouts.push(timeout);
      } catch (e) {
        // user doesn't have permission
        setRun(null);
      }
    };

    check();

    return () => timeouts.forEach((timeout) => window.clearTimeout(timeout));
  }, []);

  if (!run) {
    return null;
  }

  let conclusionText;

  switch (run.conclusion) {
    case 'cancelled':
      conclusionText = 'cancelled ';
      break;
    case 'failure':
      conclusionText = 'failed ';
      break;
    default:
      conclusionText = ' ';
      break;
  }

  let message = (
    <span className={classes.progressText}>
      <div className={classes.spinner}>
        <CircularProgress color="secondary" size={16} />
      </div>
      Deploy in progress
    </span>
  );

  let action = (
    <Button
      color="secondary"
      size="small"
      onClick={async () => {
        setCancelDeploy(true);
        await axios.post(
          `${adminBaseUrl}/deploy/cancel`,
          {},
          {
            crossDomain: true,
            withCredentials: true,
          }
        );
      }}
    >
      Cancel
    </Button>
  );

  if (run.status === 'completed') {
    message = (
      <>
        Last deploy {conclusionText}
        <span className={classes.timestamp}>
          <RelativeMoment tooltipDateFormat="MMMM Do, YYYY [at] h:mma">
            {run.lastRun}
          </RelativeMoment>
        </span>
      </>
    );

    action = (
      <>
        <Box display="inline-block" mr={1}>
          <Button color="secondary" size="small" href={adminBaseUrl}>
            Visit Admin
          </Button>
        </Box>
        <Button
          variant="contained"
          size="small"
          onClick={async () => {
            setStartDeploy(true);
            await axios.post(
              `${adminBaseUrl}/deploy/rebuild`,
              {},
              {
                crossDomain: true,
                withCredentials: true,
              }
            );
          }}
        >
          Deploy Now
        </Button>
      </>
    );
  }

  if (startDeploy) {
    message = (
      <span className={classes.progressText}>
        <div className={classes.spinner}>
          <CircularProgress color="secondary" size={16} />
        </div>
        Starting deploy
      </span>
    );

    action = null;
  }

  if (cancelDeploy) {
    message = (
      <span className={classes.progressText}>
        <div className={classes.spinner}>
          <CircularProgress color="secondary" size={16} />
        </div>
        Canceling deploy
      </span>
    );

    action = null;
  }

  return (
    <Snackbar
      open
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      message={message}
      action={action}
      classes={{ root: classes.snackbar }}
    />
  );
};

export default Deploy;
