import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useEmblaCarousel } from 'embla-carousel/react';

import { formatMemoryVerseLevels } from '../utils';
import Image from './Image';

const useStyles = makeStyles((theme) => ({
  carousel: {
    overflow: 'hidden',
    '&.is-dragging': {
      cursor: 'grab',
    },
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  slides: {
    display: 'flex',
  },
  slide: {
    position: 'relative',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  image: {
    maxWidth: '640px',
    width: '60vw',
  },
  caption: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    color: '#fff',
    padding: theme.spacing(2),
  },
  textOnly: {
    width: '100%',
    paddingTop: '100%',
    position: 'relative',
    background: '#eeeeee',
  },
  text: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    padding: theme.spacing(4),
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.h5.fontSize,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.h4.fontSize,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h1.fontSize,
    },
  },
  reference: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.h6.fontSize,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.h5.fontSize,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
}));

const MemoryVerseSnippet = ({ title, memoryVerses }) => {
  const classes = useStyles();
  const [paused, setPaused] = useState(false);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: 'center',
  });

  useEffect(() => {
    if (!emblaApi) {
      return undefined;
    }

    const timer = setInterval(() => {
      if (paused) {
        return;
      }
      emblaApi.scrollNext();
    }, 4000);

    emblaApi.on('pointerDown', () => setPaused(true));

    return () => clearInterval(timer);
  }, [emblaApi, paused]);

  return (
    <>
      {title && (
        <Box mb={4}>
          <Typography variant="h4">{title}</Typography>
        </Box>
      )}

      <div className={classes.carousel} ref={emblaRef}>
        <div className={classes.slides}>
          {memoryVerses.map((memoryVerse, i) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={`${memoryVerse.id}-${i}`} className={classes.slide}>
              <div className={classes.image}>
                {memoryVerse.image ? (
                  <Image
                    src={memoryVerse.image}
                    alt={`"${memoryVerse.text}" —${memoryVerse.reference}, ${memoryVerse.translation}`}
                  />
                ) : (
                  <div className={classes.textOnly}>
                    <div className={classes.text}>
                      <div>
                        {memoryVerse.text}
                        <div className={classes.reference}>
                          &mdash;{memoryVerse.reference},{' '}
                          {memoryVerse.translation}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.caption}>
                <Typography variant="body2">
                  {formatMemoryVerseLevels(memoryVerse)}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

MemoryVerseSnippet.propTypes = {
  title: PropTypes.string,
  memoryVerses: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MemoryVerseSnippet.defaultProps = {
  title: null,
};

export default MemoryVerseSnippet;
